<template>
  <div class="scroll">
    <div class="text-h5">出社予定日</div>
    <v-divider style="margin: 5px 0px"></v-divider>
    <MyArrivalSet></MyArrivalSet>
    <v-divider style="margin: 5px 0px"></v-divider>
    <ArrivalCount :list="changeData" :group="group" :presence="presence"></ArrivalCount>
    <UserArrivalList :list="changeData" :group="group" :presence="presence"></UserArrivalList>
  </div>
</template>

<script>
import firebase, {firebase_db} from "../../plugins/firebase";
import UserArrivalList from "./UserArrivalList";
import MyArrivalSet from "./MyArrivalSet"
import ArrivalCount from "./ArrivalCount";

export default {
  name: "ArrivalSet",
  components: {ArrivalCount, MyArrivalSet, UserArrivalList},
  data: () => ({
    users : [],
    presence : [],
    group: [],
    currentUser: [],
  }),
  async created() {
    await firebase.onAuth();
    await this.getUserInfo();
    await this.getCollectionUsers();
    await this.getCollectionPresence();
    await this.getCollectionGroups();
  },
  computed: {
    changeData() {
      const group = this.currentUser.group_id;
      return this.users.filter(function (item) {
        if (group === 'all') {
          return true
        } else if (group.toString() === item.group_id.toString()) {
          return true
        }
      }, group);
    }
  },
  methods: {
    getUserInfo() {
      let uid = firebase.auth().currentUser.uid;
      let collection = firebase_db.collection('users').where('uid', '==', uid);
      collection.onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          this.currentUser = doc.data();
          this.currentUser['docId'] = doc.id;
          if (!this.currentUser.arrival) {
            this.currentUser.arrival = [];
          }
        })
      });
    },
    getCollectionUsers() {
      let collection = firebase_db.collection('users').orderBy(`employee_id`)
      collection.onSnapshot(snapshot => {
        this.users = [];
        snapshot.forEach(doc => {
          this.users.push(doc.data())
        })
      });
    },
    getCollectionGroups() {
      firebase_db.collection('group').orderBy('sort').onSnapshot(snapshot => {
        this.group = [];
        this.select_group = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.group.push(tmpVar);
          this.select_group.push(tmpVar);
        })
      });
    },
    getCollectionPresence() {
      firebase_db.collection('presence').orderBy('sort').onSnapshot(snapshot => {
        this.presence = [];
        this.select_presence = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.presence.push(tmpVar);
          this.select_presence.push(tmpVar);
        })
      });
    },
  }
}
</script>

<style scoped>

.scroll table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border:2px solid #BDBDBD;
}

.scroll td, th {
  padding: 5px 10px;
  border:2px solid #BDBDBD;
  text-align: center;
}

.scroll{
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  overflow-x: scroll;
  height: 85vh;
}
.scroll::-webkit-scrollbar{
  height: 5px;
}
.scroll::-webkit-scrollbar-track{
  background: #F1F1F1;
}
.scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

</style>