<template>
  <table>
    <thead>
    <tr>
      <th style="width: 200px;"></th>
      <th v-for="(day, index) in calendar" :key="index" v-bind:style="{ background:dayOfWeek(day) }">{{ day }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ this.currentUser!=null ? this.currentUser.name_jp : "" }}</td>
      <td v-for="(day, index) in calendar" :key="index" v-bind:style="{ background:dayOfWeek(day) }">
        <v-btn v-bind:color="isArrivalDay(day) ? 'success' : ''" @click="arrivalDayChange(day)" >{{ isArrivalDay(day) ? "出社" : "自宅" }}</v-btn>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";
import firebase, {firebase_db} from "../../plugins/firebase";

export default {
  name: "MyArrivalSet",
  data: () => ({
    calendar: [],
    currentUser: [],
  }),
  async created() {
    await firebase.onAuth();
    await this.daysSet();
    await this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let uid = firebase.auth().currentUser.uid;
      let collection = firebase_db.collection('users').where('uid', '==', uid);
      collection.onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          this.currentUser = doc.data();
          this.currentUser['docId'] = doc.id;
          if (!this.currentUser.arrival) {
            this.currentUser.arrival = [];
          }
        })
      });
    },
    //１ヶ月分の日付生成
    daysSet() {
      moment.locale( 'ja' );
      for(let days = 0; days <= 30; days++) {
        let day = moment().add(days, 'days').format("YYYY/MM/DD");
        this.calendar.push(day);
      }
    },
    dayOfWeek(day) {
      moment.locale( 'ja' );
      let week = moment(day).format("e");
      if (week == 0) {
        return "#FFCDD2";
      } else if (week == 6) {
        return "#BBDEFB";
      } else {
        return "#FFFFFF"
      }
    },
    // 状態取得
    isArrivalDay(day) {
      if (!this.currentUser.arrival)
        return false;

      if (this.currentUser.arrival.find(element => element === day)) {
        return true;
      } else {
        return false;
      }
    },
    // 配列の置き換え
    arrivalDayChange(day) {
      if (!this.currentUser.arrival)
        return false;

      if (this.isArrivalDay(day)) {
        const index = this.currentUser.arrival.findIndex(element => element === day)
        this.currentUser.arrival.splice(index,1);
      } else {
        this.currentUser.arrival.push(day);
      }
      //今日より古いarrayがある時削除
      let today = moment().format("YYYY/MM/DD");
      this.currentUser.arrival.forEach(arrival => {
        if (arrival < today) {
          const index = this.currentUser.arrival.findIndex(element => element === arrival)
          this.currentUser.arrival.splice(index,1);
        }
      })
      this.setArrival()
    },
    setArrival() {
      //mergeを入れないと全部消えちゃうので注意
      firebase_db.collection("users").doc(this.currentUser.docId).set({
        arrival: this.currentUser.arrival,
        update_time: firebase.firestore.Timestamp.now(),
      },{ merge:true });
    },
  }
}
</script>

<style scoped>
table {
  border:2px solid #BDBDBD;
}

td, th {
  width: 110px;
  padding: 5px 10px;
  border-collapse: collapse;
  border:2px solid #BDBDBD;
  text-align: center;
}

</style>