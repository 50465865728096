<template>
  <table>
    <thead>
    <tr>
      <th style="width: 200px;">{{ sort_key }}  {{ (sort_key!=="") ? (sort_asc ? '↑' : '↓') : "" }}</th>
      <th v-for="(day, index) in calendar" :key="index" v-bind:style="{ background:dayOfWeek(day)}" @click="sortBy(day)" >{{ day }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(user, user_index) in this.filter_office_location(this.sort_users)" :key="user_index" >
      <td>{{ user["name"] }}</td>
      <td v-for="(day, day_index) in calendar" :key="day_index" v-bind:style="{ background:dayOfWeek(day) }" :class="`day-index-${day_index}`">
        {{ user[day] }}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";

export default {
  name: "UserArrivalList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    calendar: [],
    users : [],
    sort_key: "",
    sort_asc: true,
  }),
  created() {
    this.dayInit();
  },
  watch: {
    list: {
      handler(newVal) {
        this.dayInit();   //初期化が間に合わない場合の保険
        this.users.splice(0, this.users.length);
        for(let eleIndex = 0; eleIndex < newVal.length; eleIndex++) {
          let calObj = {};
          calObj["name"] = newVal[eleIndex].name_jp;
          calObj["office_location"] = newVal[eleIndex].office_location;
          for(let dayIndex = 0; dayIndex < this.calendar.length; dayIndex++) {
            let bool = false;
            if (newVal[eleIndex].arrival) {
              bool = newVal[eleIndex].arrival.find(element => element === this.calendar[dayIndex]);
            }
            calObj[this.calendar[dayIndex]] = (bool) ? "出社" : "";
          }
          this.users.push(calObj);
        }
      },
      immediate: true,
    }
  },
  computed: {
    sort_users() {
      if (this.sort_key !== "") {
        let set = 1;
        this.sort_asc ? (set = 1) : (set = -1);
        return this.users.slice().sort((a, b) => {
//          console.log(a[this.sort_key].length + "<" + b[this.sort_key].length);
          if (a[this.sort_key].length < b[this.sort_key].length) return -1 * set;
          if (a[this.sort_key].length > b[this.sort_key].length) return set;
          return 0;
        });
      } else {
        return this.users;
      }
    },
  },
  methods: {
    dayInit() {
      if (this.calendar.length === 0) {
        moment.locale( 'ja' );
        for(let days = 0; days <= 30; days++) {
          let day = moment().add(days, 'days').format("YYYY/MM/DD");
          this.calendar.push(day);
        }
      }
    },
    //勤務地フィルター（仮）本社縛り
    filter_office_location(users) {
      if (users.length > 0) {
        return users.filter(function (item) {
          if (item.office_location === 1) {
            return true
          }
        });
      }
    },
    sortBy(key) {
      this.sort_key === key
          ? (this.sort_asc = !this.sort_asc)
          : (this.sort_asc = true);
      this.sort_key = key;
    },
    dayOfWeek(day) {
      moment.locale( 'ja' );
      let week = moment(day).format("e");
      if (week == 0) {
        return "#FFCDD2";
      } else if (week == 6) {
        return "#BBDEFB";
      } else {
        return "#FFFFFF"
      }
    },
  }
}
</script>

<style scoped>

table {
  border:2px solid #BDBDBD;
}

td, th {
  width: 110px;
  padding: 5px 10px;
  border-collapse: collapse;
  border:2px solid #BDBDBD;
  text-align: center;
}


</style>