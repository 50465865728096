<template>
  <table>
    <tbody>
      <tr>
        <td style="width: 200px;">出社予定数</td>
        <td v-for="(day, index) in calendar" :key="index" v-bind:style="{ background:dayOfWeek(day) }">
          {{ day_counters[index] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";
//import {firebase_db} from "../../plugins/firebase";

export default {
  name: "ArrivalCount",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    calendar: [],
    day_counters: [],
    users : [],
  }),
  async created() {
    await this.dayInit();
    //await this.getArrivalUserDays();
  },
  watch: {
    users: {
      handler(newVal) {
        // console.log(newVal)
        //配列初期化
        this.day_counters.splice(0, this.day_counters.length);
        for(let days = 0; days <= 30; days++) {
          this.day_counters.push(0);
        }
        //本社縛り
        const users = newVal.filter(function (item) {
          if (item.office_location === 1) {
            return true
          }
        });
        const arrivalDays = users.filter(user => (user.arrival)).map(user => ({
          arrival: user.arrival
        }));
        let arr = {};
        //日付に合わせてカウントアップ
        for (let index = 0; index < arrivalDays.length; index++) {
          for (let index2 = 0; index2 < arrivalDays[index]['arrival'].length; index2++) {
            arr[[arrivalDays[index]['arrival'][index2]]] = (arr[[arrivalDays[index]['arrival'][index2]]]) ? arr[[arrivalDays[index]['arrival'][index2]]] + 1 : 1;
            let key = arrivalDays[index]['arrival'][index2];
            const keyIndex = this.calendar.findIndex(item => item === key);
            if (keyIndex === -1)
              continue;
            this.$set(this.day_counters, keyIndex, arr[[arrivalDays[index]['arrival'][index2]]]);
          }
        }
      }
    },
    list: {
      handler(newVal) {
        this.users = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dayInit() {
      moment.locale( 'ja' );
      for(let days = 0; days <= 30; days++) {
        let day = moment().add(days, 'days').format("YYYY/MM/DD");
        this.calendar.push(day);
        this.day_counters.push(0);
      }
    },
    dayOfWeek(day) {
      moment.locale( 'ja' );
      let week = moment(day).format("e");
      if (week == 0) {
        return "#FFCDD2";
      } else if (week == 6) {
        return "#BBDEFB";
      } else {
        return "#FFFFFF"
      }
    },
    // getArrivalUserDays() {
    //   let collection = firebase_db.collection('users').orderBy(`employee_id`)
    //   collection.onSnapshot(snapshot => {
    //     this.users = [];
    //     snapshot.forEach(doc => {
    //       this.users.push(doc.data())
    //     })
    //   });
    // },
  }
}
</script>

<style scoped>

table {
  border:2px solid #BDBDBD;
  margin-top: 5px;
  margin-bottom: 5px;
}

td, th {
  width: 110px;
  padding: 5px 10px;
  border-collapse: collapse;
  border:2px solid #BDBDBD;
  text-align: center;
}

</style>