<template>
  <v-container fluid>
    <arrival-set></arrival-set>
  </v-container>
</template>

<script>


import ArrivalSet from "../../molecules/ArrivalSet";
export default {
  name: "DateOfArrival",
  components: {ArrivalSet}
}
</script>

<style scoped>

</style>